<template>
  <v-app id="inspire">
    <v-content>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
          <v-form @submit.prevent>
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Johnson Medication</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
              <v-card-text>
                  <v-text-field
                    label="Login"
                    name="login"
                    prepend-icon="person"
                    type="text"
                    v-model="username"
                    :rules="[required('username')]"
                  />

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                    v-model="password"
                    :rules="[required('password')]"
                  />
                <div class="row red--text text-center ml-6" v-if="authStatus == 'error'" 
                  >
                   Username and password did not match
                </div> 
              </v-card-text>
              <v-card-actions class="justify-center">
                  <v-btn @click="login" color="primary">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import validations from "@/services/utils/validation";

    export default {
        name: 'login',
        data () {
            return {
                username: '',
                password: '',
                keepLoggedIn: false,
                usernameErrors: [],
                passwordErrors: [],
                ...validations
            }
            
        },
        computed: {
          ...mapGetters([
            'authStatus',
            ]),
            formReady () {
                return !this.usernameErrors.length && !this.passwordErrors.length
            },
        },
        methods: {
            login () {
              if(this.username.length>0 && this.password.length>0){
                  this.$store.dispatch('login', {
                    username: this.username,
                    password: this.password,
                })
                /* eslint-disable */
                .then(response => {
                    const token = response.data.token
                    localStorage.setItem('token', token)
                    this.$router.push('/users/me')
                })
                .catch(error => {
                    console.log(error)
                })
              }
              else {
                this.validateField()
              }            
            },
            validateField () {
              this.$refs.form.validate()
            },
        },
    }
</script>

<style lang="scss">
</style>
